import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CalendarKendo = _resolveComponent("CalendarKendo")

  return ($setup.loaded)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode(_component_CalendarKendo, {
          changeDate: true,
          removePastSlots: true,
          onOnselectslot: $setup.onselectslot,
          product: $setup.product.productId,
          priceTypeId: $setup.priceTypeId,
          customerId: $setup.customerId,
          selectedDateProp: $setup.toChangeDate?.date.substr(0, $setup.toChangeDate?.date.length - 2),
          date: $setup.product.date,
          selectedSlotId: $setup.product.slotId
        }, null, 8, ["onOnselectslot", "product", "priceTypeId", "customerId", "selectedDateProp", "date", "selectedSlotId"])
      ]))
    : _createCommentVNode("", true)
}