
import CalendarKendo from '@/components/Calendar/CalendarKendo.vue';

import swal from 'sweetalert2';
import { ref, onMounted } from 'vue';
import { OrderProduct } from '@/models/Order';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import mixin from '@/mixin';
import moment from 'moment';
import { generalStore } from '@/store';
import { CustomerToChangeDate } from '@/models/Interfaces';

export default {
    components: { CalendarKendo },
    async setup() {
        const { t } = useI18n();
        const loaded = ref(false);
        const product = ref(new OrderProduct());
        const priceTypeId = ref('');
        const customerId = '';
        const toChangeDate = ref<CustomerToChangeDate | null>(null);

        function Cancel() {
            router.push({ name: 'neworder' });
        }

        async function SaveNewOrderProductDate(): Promise<boolean> {
            if (toChangeDate.value == null || !generalStore.state.order) {
                return false;
            }
            if (toChangeDate.value.couponId) {
                const coupon = generalStore.state.order?.coupons.find(x => x.id == toChangeDate.value?.couponId);
                if (!coupon) {
                    return false;
                }
                if (
                    moment(product.value.date).isBefore(moment(coupon.validDateFrom)) ||
                    moment(product.value.date).isAfter(moment(coupon.validDateTo))
                ) {
                    swal.fire({
                        icon: 'error',
                        text: t('alert.CouponDateExepted')
                    });
                    return false;
                }
            }

            const productStore = generalStore.state.order.products.find(x => x.productId == toChangeDate.value?.productId);
            if (!productStore) {
                return false;
            }
            const customerStore = productStore.customers.find(
                x => x.id == toChangeDate.value?.customerId && x.date == toChangeDate.value?.date
            );
            if (!customerStore) {
                return false;
            }

            customerStore.date = `${product.value.date}T${product.value.slot.startTime}:00`;
            customerStore.slot = product.value.slot;
            customerStore.slotId = product.value.slot.id;
            return true;
        }

        async function onselectslot({ slotId, slot, date }: any) {
            product.value.date = date;
            product.value.slotId = slotId;
            product.value.slot = slot;
            product.value.customers = [];
            const confirm = await SaveNewOrderProductDate();
            if (confirm) {
                router.push({ name: 'neworder' });
            }
        }
        async function onMountedAction() {
            swal.showLoading();
            toChangeDate.value = generalStore.state.customerToChangeDate;
            if (toChangeDate.value == null) {
                router.replace({ name: 'neworder' });
                return;
            }
            product.value.slotId = toChangeDate.value.slot.id;
            product.value.slot = toChangeDate.value.slot;
            product.value.productId = toChangeDate.value.productId;
            product.value.date = toChangeDate.value.date.split('T')[0];
            product.value.calendarDate = mixin.methods.getDateToTitle(toChangeDate.value.date);
            priceTypeId.value = toChangeDate.value.priceTypeId;
            loaded.value = true;
            swal.close();
        }

        onMounted(onMountedAction);

        return { product, priceTypeId, Cancel, onselectslot, customerId, loaded, toChangeDate };
    }
};
